<template>
  <div class="orderDetail center-wrap">
    <div class="big-box" v-if="order">
      <!-- 收货地址 -->
      <div class="address-box">
        <div class="order-title">
          <h1 v-text="$t('detail.adderss')">收货地址</h1>
          <h5 v-text="$t('detail.invoice_information')" v-if="order.bill_info">发票信息</h5>
        </div>
        <div class="goods">
          <!-- 收货地址信息 -->
          <div class="address">
            <div>
              <p v-text="$t('detail.people')">收货人：</p>
              <h2>{{order.address_info.name}}</h2>
            </div>
            <div>
              <p v-text="$t('detail.number')">联系方式：</p>
              <h2>{{order.address_info.mobile}}</h2>
            </div>
            <div>
              <p v-text="$t('detail.adderss')">收货地址：</p>
              <h2>{{order.address_info.province + order.address_info.city + order.address_info.area + order.address_info.address}}</h2>
            </div>
            <div v-if="order.transport_company">
              <p v-text="$t('detail.transport_company')">物流公司：</p>
              <h2>{{order.transport_company | showTransportCompany}}</h2>
            </div>
            <div v-if="order.transport_code">
              <p v-text="$t('detail.transport_code')">物流单号：</p>
              <h2>{{order.transport_code}}</h2>
            </div>
          </div>
          <!-- 订单物流信息 -->
          <div v-if="transportInfo">
            <div class="state">
              <div v-for="(item,index) in transportInfo" :key="index">
                <p>{{item.time}}:</p>
                <div class="state-div">
                  <h2>{{item.context}}</h2>
                </div>
              </div>
            </div>
          </div>
          <div class="state" v-else>
            <div>{{ $t('order.logistics_empty')}}</div>
          </div>

          <!-- 发票信息待发货待收货没有 -->
          <div class="invoice" v-if="order.bill_info">
            <div>
              <h2>{{order.bill_info.title}}</h2>
            </div>
            <div>
              <p v-text="$t('detail.type')">类型：</p>
              <h2>{{order.bill_info.type == 1?'企业':'个人'}}</h2>
            </div>
            <div>
              <p v-text="$t('detail.taxes_num')" v-if="order.bill_info.type == 1">纳税号：</p>
              <h2>{{order.bill_info.number}}</h2>
            </div>
            <div>
              <p v-text="$t('detail.bill_reason')">原因：</p>
              <h2>{{order.bill_info.disagree_reason}}</h2>
            </div>
            <div class="blue-botton" v-if="order.bill_info.status == 0">待处理</div>
            <div class="blue-botton" v-if="order.bill_info.status == 1">已通过</div>
            <div class="red-botton" v-if="order.bill_info.status == 2">未通过</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'OrderLogistics',
  data() {
    return {
      order: null,
      transportInfo: null, // 订单物流信息
    }
  },

  created() {
    this.httpOrderDetail()
  },

  methods: {
    /**
     * 请求订单分单详情
     */
    httpOrderDetail: function () {
      var self = this
      self.common
        .httpPost('/api/user/orderDetail', {
          id: self.$route.query.id,
        })
        .then((ret) => {
          if (ret.code == 1) {
            self.order = ret.data
            if (self.order.transport_code && self.order.transport_company) {
              self.httpTransportInfo(
                self.order.transport_code,
                self.order.transport_company
              )
            }
          } else self.common.showMessage(ret.msg)
        })
    },

    /**
     * 查询订单的物流信息
     * @param  {[type]} transport_code    [物流单号]
     * @param  {[type]} transport_company [物流公司]
     */
    httpTransportInfo: function (transport_code, transport_company) {
      var self = this
      self.common
        .httpPost('/api/ajax/transportInfo', {
          num: transport_code,
          com: transport_company,
        })
        .then((ret) => {
          if (ret.status == '200' && ret.message == 'ok') {
            self.transportInfo = ret.data
          }
        })
    },
  },

  filters: {
    showTransportCompany: function (value) {
      if (!value) return ''
      var index = value.indexOf('/')
      if (index == -1) return value
      else return value.substring(0, index)
    },
  },
}
</script>
<style scoped>
.orderDetail {
  min-height: 500px;
  padding-bottom: 50px;
}

.big-box {
  margin-left: 83px;
  margin-right: 83px;
  margin-top: 20px;
}

.order-box {
  border: 1px solid rgba(182, 182, 182, 0.7);
}

.order-title {
  height: 50px;
  background: rgba(247, 246, 246, 1);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.order-title .submit-btn {
  margin-right: 50px;
}

.order-title div {
  display: flex;
  align-items: center;
}

h1 {
  font-size: 16px;
  font-weight: 500;
  color: rgba(153, 153, 153, 1);
  line-height: 18px;
  margin-left: 30px;
  padding-bottom: 0 !important;
}

.order-title div p {
  font-size: 16px;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
  line-height: 24px;
  margin-left: 30px;
}

.order-body {
  height: 148px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 30px;
  margin-right: 30px;
}

.order-time {
  width: 320px;
}

.order-time > div {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
}

.red-text {
  border-bottom: 1px dashed rgba(151, 151, 151, 0.7);
}

.red-text p {
  font-size: 16px;
  font-weight: 400;
  color: rgba(225, 36, 27, 1);
  line-height: 24px;
  margin-right: 15px;
}

.red-text div {
  font-size: 16px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 24px;
}

.reason p {
  font-size: 14px;
  font-weight: 500;
  color: rgba(179, 179, 179, 1);
  line-height: 24px;
  margin-right: 15px;
}

h2 {
  font-size: 14px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 14px;
}

.go-botton {
  width: 153px;
  height: 42px;
  background: rgba(41, 127, 171, 1);
  font-size: 16px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  line-height: 42px;
  text-align: center;
}

.book-body {
  padding-left: 30px;
  padding-right: 30px;
}

.book-money {
  height: 204px;
  display: flex;
  align-items: center;
  border-bottom: 1px dashed rgba(151, 151, 151, 0.7);
}

.book-money:last-child {
  border-bottom: 0;
}

.book-money:first-child {
  margin-top: 5px;
}

.book-money > img {
  height: 144px;
  width: 144px;
  margin-right: 90px;
}

.book-name {
  width: 220px;
  min-height: 120px;
  margin-right: 150px;
}

.name-title {
  font-size: 16px;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
  line-height: 22px;
  padding-bottom: 10px;
  border-bottom: 1px dashed rgba(151, 151, 151, 0.7);
  margin-bottom: 10px;
  word-break: break-all;
  overflow: hidden;
  /* height: 57px; */
  max-height: 59px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.book-name p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  line-height: 20px;
  margin-bottom: 15px;
}

.book-name > div {
  display: flex;
  flex-wrap: wrap;
}

.book-name div div {
  width: 72px;
  height: 20px;
  border: 1px solid rgba(41, 127, 171, 0.7);
  font-size: 12px;
  font-weight: 400;
  color: rgba(41, 127, 171, 1);
  line-height: 20px;
  text-align: center;
  margin-right: 10px;
  margin-bottom: 10px;
}

/*.money-text {
            min-height: 40px;
        }*/

.money-text span {
  font-size: 14px;
  font-family: 'PingFangSC-Medium', sans-serif;
  color: #999999;
  line-height: 16px;
}

.money-text > div {
  display: flex;
  align-items: center;
}

.money-text > div > p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(179, 179, 179, 1);
  line-height: 14px;
}

.money-text > div > div {
  margin-right: 28px;
}

.money-text > div > div p {
  font-size: 14px;
  font-family: 'PingFangSC-Medium', sans-serif;
  color: #3f3f3f;
  line-height: 16px;
}

h3 {
  font-size: 14px;
  font-weight: 400;
  font-family: 'PingFangSC-Medium', sans-serif;
  color: rgba(179, 179, 179, 1);
  line-height: 16px;
  margin-left: 45px;
}

.percentage-money {
  margin-left: 200px;
}

.percentage-money p {
  font-size: 18px;
  color: #297fab;
  line-height: 18px;
  font-family: 'PingFangSC-Medium', sans-serif;
}

.percentage-money span {
  font-size: 18px;
  color: #999999;
  line-height: 18px;
  font-family: 'PingFangSC-Medium', sans-serif;
}

.percentage-money div {
  font-size: 18px;
  color: rgba(179, 179, 179, 1);
  line-height: 18px;
  margin-top: 1px;
  margin-left: 57px;
  font-family: 'PingFangSC-Medium', sans-serif;
}

.preferential-box {
  display: flex;
  justify-content: flex-end;
  margin-top: 35px;
  margin-bottom: 40px;
}

.preferential {
  padding-bottom: 5px;
  border-bottom: 1px dashed rgba(151, 151, 151, 0.7);
}

.preferential > div {
  display: flex;
  margin-bottom: 20px;
}

.preferential div > p {
  font-size: 20px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 25px;
}

h4 {
  width: 156px;
  font-size: 16px;
  font-weight: 500;
  color: rgba(153, 153, 153, 1);
  line-height: 25px;
  text-align: right;
  margin-right: 33px;
}

.preferential-add > div {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}

.preferential-add > div:last-child {
  margin-bottom: 0px;
}

.preferential-add div div {
  width: 100px;
  height: 25px;
  border: 1px solid rgba(153, 153, 153, 0.7);
  font-size: 16px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  line-height: 25px;
  text-align: center;
  margin-right: 13px;
}

.preferential-add div p {
  font-size: 18px;
  font-weight: 400;
  color: rgba(41, 127, 171, 1);
  line-height: 14px;
}

.preferential-money > div {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.preferential-money div p {
  font-size: 20px;
  font-weight: 500;
  color: rgba(225, 36, 27, 1);
  line-height: 16px;
}

.preferential-money div div {
  font-size: 18px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 16px;
}

h5 {
  font-size: 16px;
  font-weight: 500;
  color: rgba(153, 153, 153, 1);
  line-height: 18px;
  margin-right: 260px;
}

.goods {
  padding: 30px;
  display: flex;
}

.address-box {
  border: 1px solid rgba(182, 182, 182, 0.7);
  margin-bottom: 33px;
}

.address {
  width: 346px;
  border-right: 1px dashed rgba(151, 151, 151, 0.7);
}

.address div {
  margin-bottom: 14px;
  display: flex;
  align-items: center;
}

.address div h2 {
  margin-left: 10px;
  text-align: left;
  flex: 1;
}

.address div:last-child {
  margin-bottom: 0px;
}

.address div p {
  width: 75px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  line-height: 14px;
}

.state {
  width: 435px;
  padding-left: 30px;
  border-right: 1px solid rgba(151, 151, 151, 0.7);
}

.state > div {
  margin-bottom: 14px;
  display: flex;
}

.state > div:last-child {
  margin-bottom: 0px;
}

.state div > p {
  width: 100px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  line-height: 14px;
}

.state-div {
  flex: 1;
  padding-right: 15px;
}

.state-div div {
  font-size: 14px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  line-height: 14px;
  margin-top: 14px;
}

.invoice {
  padding-left: 30px;
}

.invoice div {
  margin-bottom: 14px;
  display: flex;
  align-items: center;
}

.invoice div:last-child {
  margin-bottom: 0px;
}

.invoice div p {
  width: 58px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  line-height: 14px;
}

.red-botton {
  width: 50px;
  height: 20px;
  border: 1px solid rgba(225, 36, 27, 0.7);
  font-size: 12px;
  font-weight: 400;
  color: rgba(225, 36, 27, 1);
  line-height: 20px;
  justify-content: center;
}

.textarea-box {
  height: 105px;
  padding: 30px;
  border: 1px solid rgba(182, 182, 182, 0.7);
  margin-top: 15px;
}

.textarea-box textarea {
  width: 100%;
  height: 105px;
}

.payment {
  height: 60px;
  margin-top: 30px;
  margin-bottom: 50px;
  border: 1px solid rgba(182, 182, 182, 0.7);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.payment > div {
  display: flex;
  align-items: center;
}

.payment > p {
  flex: 1;
  margin-left: 40px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(179, 179, 179, 1);
  line-height: 20px;
}

.payment-botton > div {
  text-align: right;
  margin-right: 20px;
}

.payment-botton div div {
  font-size: 14px;
  color: rgba(179, 179, 179, 1);
  line-height: 16px;
  font-family: 'PingFangSC-Medium', sans-serif;
}

.payment-botton div p {
  font-size: 22px;
  font-family: 'PingFangSC-Medium', sans-serif;
  color: rgba(225, 36, 27, 1);
  line-height: 33px;
  letter-spacing: 1px;
}

.payment-botton > p {
  width: 190px;
  font-size: 18px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  line-height: 60px;
  background: #297fab;
  text-align: center;
}

.payment-text p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.payment-redtext {
  color: rgba(225, 36, 27, 1);
  margin-left: 30px;
  margin-right: 15px;
}

/*待确认页面css*/
.reason {
  display: flex;
  align-items: center;
}

.reason div {
  font-size: 16px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 24px;
}

.reason img {
  height: 14px;
  width: 14px;
  margin-right: 7px;
}

.go-look {
  font-size: 16px;
  font-weight: 400;
  color: rgba(41, 127, 171, 1);
  line-height: 16px;
}

.again {
  font-size: 16px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  line-height: 30px;
  width: 84px;
  height: 30px;
  background: rgba(255, 255, 255, 1);
  border-radius: 2px;
  border: 1px solid rgba(153, 153, 153, 0.7);
  text-align: center;
  margin-right: 30px;
}

.blue-botton {
  width: 50px;
  height: 20px;
  border: 1px solid rgba(41, 127, 171, 0.7);
  font-size: 12px;
  font-weight: 400;
  color: rgba(41, 127, 171, 1);
  line-height: 20px;
  justify-content: center;
}

.service-state {
  margin-top: 25px;
  margin-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  justify-content: space-between;
}

.service-box {
  height: 23px;
  display: flex;
  align-items: center;
}

.service-box img {
  height: 23px;
  width: 23px;
  margin-right: 8px;
}

.service-box p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  line-height: 14px;
}

.service-time {
  padding-top: 5px;
}

.service-time div {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.service-time div:last-child {
  margin-bottom: 0px;
}

.time-title {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 14px;
}

.time-body {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 14px;
}

/*待收货时  css */
.confirm {
  width: 153px;
  height: 42px;
  background: #3687b0;
  font-size: 16px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  line-height: 42px;
  text-align: center;
}

.add-bluetext {
  font-size: 14px;
  font-weight: 400;
  color: rgba(41, 127, 171, 1);
  line-height: 20px;
  margin-left: 30px;
}

.invoice-box {
  margin: 10px 30px;
}

.add-invoice {
  display: flex;
  align-items: center;
}

.add-invoice img {
  height: 20px;
  width: 20px;
  margin-right: 20px;
}

.add-invoice > div {
  min-width: 442px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dashed rgba(182, 182, 182, 0.7);
}

.add-invoice:last-child > div {
  border-bottom: 0;
}

.add-invoice div > p {
  font-size: 12px;
  font-weight: 400;
  color: rgba(179, 179, 179, 1);
  line-height: 12px;
}

.add-invoice div div > div {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.add-invoice div div p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  line-height: 14px;
  margin-right: 20px;
}

/*已收货css        */
.orderover-box {
  border: 1px solid rgba(182, 182, 182, 0.7);
  margin-bottom: 20px;
}

.orderover-title {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.orderover-title div {
  display: flex;
  align-items: center;
}

.orderover-title div p {
  font-size: 16px;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
  line-height: 24px;
  margin-left: 30px;
}

/*支付方式*/
.mode-payment {
  border: 1px solid rgba(182, 182, 182, 0.7);
  margin-top: 40px;
}

.log-box {
  height: 165px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.log-box div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 80px;
}

.log-box div:last-child {
  margin-right: 0;
}

.dot {
  height: 20px;
  width: 20px;
}

.logo {
  height: 36px;
  width: 36px;
  margin-left: 15px;
  margin-right: 10px;
}

.log-box div p {
  font-size: 14px;
  font-weight: 500;
  color: rgba(102, 102, 102, 1);
  line-height: 20px;
}

.explain {
  font-size: 14px;
  font-weight: 400;
  color: rgba(225, 36, 27, 1);
  line-height: 46px;
  text-align: center;
}

.log-box .balance-value {
  display: flex;
  flex-flow: column;
  justify-items: center;
  align-items: flex-start;
}

.balance-value p {
  text-align: left;
}

.log-box .balance-value p:last-child {
  font-size: 14px;
  color: rgba(153, 153, 153, 1);
}

.order-code {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
}

.order-code p {
  font-size: 16px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  line-height: 14px;
}

.order-code span {
  font-size: 16px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 14px;
  margin-left: 5px;
}
.order-code div {
  font-size: 16px;
  font-weight: 400;
  color: rgba(54, 135, 176, 1);
  line-height: 14px;
}
</style>